import clsx from 'clsx';

const array = ['', 'A', 'T', 'H', 'E', 'N', 'A', ''];
const array2 = ['', 'L', 'O', 'A', 'D', 'I', 'N', 'G', ''];

// PART OF CSS rules placed in assets/styles/globals.css

const spanClass =
    'float-left h-0 w-9 md:w-12.5 leading-[170px] md:leading-[140px]';
const childSpanClass =
    'font-mont text-white text-3xl md:text-5xl text-center absolute bottom-0 bg-gradient-to-t from-[#4c5878] to-[transparent]';
const loaderClass =
    "w-full absolute m-auto after:content-[''] table clear-both";

const Loading = () => (
    <div className="loading flex justify-center items-center h-[100vh]">
        <div className="wrapper relative w-[315px] md:w-[450px] h-[91px] md:h-[120px]">
            <div className={clsx('loader left-4 md:left-6', loaderClass)}>
                {array.map((item, key) => (
                    <span
                        key={key}
                        className={clsx('h-[91px] md:h-[120px]', spanClass)}
                    />
                ))}

                <div className="covers h-full w-full absolute">
                    {array.map((item, key) => (
                        <span
                            className={clsx(
                                'overflow-hidden relative h-full',
                                spanClass,
                            )}
                            key={key}
                        >
                            <span className={clsx(spanClass, childSpanClass)}>
                                {item}
                            </span>
                        </span>
                    ))}
                </div>
            </div>

            <div className={clsx('loader2', loaderClass)}>
                {array2.map((item, key) => (
                    <span
                        key={key}
                        className={clsx('h-[91px] md:h-[120px]', spanClass)}
                    />
                ))}

                <div className="covers2 h-full w-full absolute">
                    {array2.map((item, key) => (
                        <span
                            className={clsx(
                                'overflow-hidden relative h-full',
                                spanClass,
                            )}
                            key={key}
                        >
                            <span className={clsx(spanClass, childSpanClass)}>
                                {item}
                            </span>
                        </span>
                    ))}
                </div>
            </div>
        </div>
    </div>
);

export default Loading;
