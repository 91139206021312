import clsx from 'clsx';
import CollectionIcon from '@heroicons/react/solid/CollectionIcon';
import TableIcon from '@heroicons/react/solid/TableIcon';

export enum DataViewMode {
    TABLE = 'table',
    COLLECTION = 'collection',
}

const dataViewCollection = [
    {
        name: DataViewMode.TABLE,
        icon: TableIcon,
    },
    {
        name: DataViewMode.COLLECTION,
        icon: CollectionIcon,
    },
];

type TProps = {
    selectedDataView: DataViewMode;
    setSelectedDataView: (value: DataViewMode) => void;
};

const DataViewSelector = ({
    selectedDataView,
    setSelectedDataView,
}: TProps) => {
    return (
        <div className="flex rounded-md border-2 ml-7.5">
            {dataViewCollection.map((item, key: number) => (
                <div
                    key={key}
                    className={clsx(
                        'w-11 h-8.5 flex items-center bg-white justify-center cursor-pointer',
                        {
                            'bg-primaryBtnBg text-white':
                                selectedDataView === item.name,
                            'rounded-l-1': item.name === DataViewMode.TABLE,
                            'rounded-r-1':
                                item.name === DataViewMode.COLLECTION,
                        },
                    )}
                    onClick={() => setSelectedDataView(item.name)}
                >
                    <item.icon className="w-7 h-4.5" />
                </div>
            ))}
        </div>
    );
};

export default DataViewSelector;
