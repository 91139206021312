import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect } from 'react';
import { UserLanguages } from 'src/constants/accountsLists';
import { ProfileKycStatus } from 'src/constants/kycLists';
import { Country } from 'src/libs/types/country';
import Timezone from 'src/libs/types/timezones';
import { TAccount, useFetchAccountData } from 'src/services/useAccount';
import { AuthContext } from './authContext';

const defaultState: {
    accountData: TAccount;
    loadingAccountData: boolean;
    fetchAccountData: () => void;
} = {
    accountData: {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        address: '',
        state: '',
        zip: '',
        country: Country.UnitedStates,
        areaOfLaw: [],
        cityId: '',
        createdAt: new Date(),
        updatedAt: null,
        createdBy: '',
        updatedBy: '',
        experience: '',
        license: '',
        licensePhotoUrl: '',
        removedAt: null,
        removedBy: '',
        status: ProfileKycStatus.UNVERIFIED,
        userId: '',
        stripeId: '',
        version: 0,
        about: '',
        isAutomaticTimeZone: false,
        language: UserLanguages.en_US,
        timeZone: Timezone['Canada/Central'],
    },
    loadingAccountData: false,
    fetchAccountData: () => {},
};

export const AccountContext = React.createContext(defaultState);

const AccountProvider = ({ children }: any) => {
    const router = useRouter();
    const { asPath } = router;
    const { userSession } = useContext(AuthContext);
    const userId = userSession?.userData.id || '';
    const [{ data }, fetchAccountData, loadingAccountData] =
        useFetchAccountData(userId);

    const debouncedFetchAccountData = useCallback(
        debounce(() => {
            fetchAccountData();
        }, 300),
        [userId],
    );

    useEffect(() => {
        userId && debouncedFetchAccountData();
    }, [userId, asPath]);

    const state = {
        accountData: data || defaultState.accountData,
        loadingAccountData,
        fetchAccountData: debouncedFetchAccountData,
    };

    return (
        <AccountContext.Provider value={state}>
            {children}
        </AccountContext.Provider>
    );
};

export default AccountProvider;
