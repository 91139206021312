import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import CheckCircleIcon from '@heroicons/react/outline/CheckCircleIcon';
import ExclamationCircleIcon from '@heroicons/react/outline/ExclamationCircleIcon';
import XIcon from '@heroicons/react/solid/XIcon';
import { WEB_BUILD_ENV } from 'src/libs/config';

export enum NotificationTypeEnum {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    NORMAL = 'NORMAL',
}

type TProps = {
    header?: string;
    description: string;
    className?: string;
    width?: string;
    onClose: () => void;
    type?: NotificationTypeEnum;
};

const IonToast = !WEB_BUILD_ENV
    ? dynamic(async () => await (await import('@ionic/react')).IonToast)
    : null;

//@ts-ignore
const { close, alertCircleOutline, checkmarkCircleOutline } = WEB_BUILD_ENV
    ? dynamic(
          async () => (await (await import('ionicons/icons')).default) as any,
      )
    : {};

const GeneralSnackbar = ({
    header,
    description,
    className,
    onClose,
    width,
    type = NotificationTypeEnum.NORMAL,
}: TProps) => {
    const [render, setRender] = useState(false);

    let color = '';
    let icon = '';
    let bgColor = '';
    let textColor = '';

    switch (type) {
        case NotificationTypeEnum.ERROR: {
            color = 'danger';
            icon = alertCircleOutline;
            bgColor = 'danger';
            textColor = 'white';
            break;
        }
        case NotificationTypeEnum.SUCCESS: {
            color = 'success';
            icon = checkmarkCircleOutline;
            bgColor = 'lime-600';
            textColor = 'white';
            break;
        }
        default: {
            color = '';
            bgColor = '';
            textColor = '';
        }
    }

    useEffect(() => {
        //FYI this is hack for proper render
        if (description) {
            setTimeout(() => {
                setRender(true);
            }, 1000);
        }
    }, [description]);

    const onCloseHandler = () => {
        setRender(false);
        onClose();
    };

    return !IonToast ? (
        <div
            className={clsx(
                `flex py-3.5 px-4 bg-white rounded-[4px] shadow-xl m-2 absolute top-0 right-0 z-50 bg-${
                    bgColor || 'white'
                } text-${textColor}`,
                className,
            )}
            style={{ width: `calc(${width} - 16px)` }}
        >
            <div className="flex items-center left min-w-6 mr-4">
                {type === NotificationTypeEnum.NORMAL ? (
                    <CheckCircleIcon className="w-5 cursor-pointer" />
                ) : null}
                {type === NotificationTypeEnum.ERROR ? (
                    <ExclamationCircleIcon className="w-5 cursor-pointer" />
                ) : null}
            </div>
            <div className="middle w-64">
                <div
                    className={`header font-semibold mb-0.5 text-sm text-${textColor}`}
                >
                    {header}
                </div>
                <div className={`body font-normal text-sm text-${textColor}`}>
                    {description}
                </div>
                <div className={`footer font-semibold flex text-${textColor}`}>
                    {/* {buttons} */}
                </div>
            </div>
            <div className="right flex items-center min-w-6 pl-2.5 pr-0.5 ml-auto">
                <XIcon className="w-4 cursor-pointer" onClick={onClose} />
            </div>
        </div>
    ) : (
        <IonToast
            isOpen={render}
            onDidDismiss={onCloseHandler}
            position="top"
            color={color}
            icon={icon || undefined}
            header={header}
            message={description}
            duration={7000}
            cssClass={clsx(
                'left-auto right-0 fixed',
                'w-[' + width + ']',
                className,
            )}
            buttons={[
                {
                    side: 'end',
                    icon: close,
                    handler: () => {
                        console.log('Favorite clicked');
                    },
                },
            ]}
        />
    );
};

export default GeneralSnackbar;
