import 'assets/styles/globals.css';
import 'assets/styles/variables.css';
import { hotjar } from 'react-hotjar';
import dynamic from 'next/dynamic';
import { Provider } from 'react-redux';
import { store } from 'src/store';
import { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import AuthProvider from 'src/contexts/authContext';
import KycStatusProvider from 'src/contexts/kycStatusContext';
import GeneralRouter from 'src/components/pages/GeneralRouter';
import AccountProvider from 'src/contexts/accountContext';

import Head from 'next/head';
import { WEB_BUILD_ENV } from 'src/libs/config';

const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID || '';

const HJID = 3077441;
const HJSV = 6;

const IonicWrapper = !WEB_BUILD_ENV
    ? dynamic(() => import('src/components/general/IonicWrapper'))
    : () => null;

function MyApp({ Component, pageProps }: AppProps) {
    const [history, setHistory] = useState<string[]>([]);

    useEffect(() => {
        hotjar.initialize(HJID, HJSV);
    }, []);

    const TrackingHeadScript = dynamic(
        async () => (await import('@phntms/next-gtm')).TrackingHeadScript,
    );
    const Loader = dynamic(() => import('src/components/general/Loader'));
    const Notifications = dynamic(
        () => import('src/components/general/Notifications'),
    );

    return (
        <>
            <Provider store={store}>
                <Head>
                    <title>Athena Legal</title>
                    <link rel="preconnect" href="https://in.hotjar.com" />
                    <link
                        rel="preconnect"
                        href="https://www.googletagmanager.com"
                    />
                    <link
                        rel="preconnect"
                        href="https://o1135108.ingest.sentry.io"
                    />
                </Head>
                <Loader />
                <Notifications />

                <AuthProvider>
                    <KycStatusProvider>
                        <AccountProvider>
                            <GeneralRouter>
                                <Component history={history} {...pageProps} />
                            </GeneralRouter>
                        </AccountProvider>
                    </KycStatusProvider>
                </AuthProvider>
            </Provider>
            <IonicWrapper history={history} setHistory={setHistory} />
            <TrackingHeadScript id={GA_TRACKING_ID} />
        </>
    );
}

export default MyApp;

MyApp.getStaticProps = async ({ Component, ctx }: any) => {
    let pageProps = {};

    if (Component.getStaticProps) {
        pageProps = await Component.getStaticProps(ctx);
    }

    return { pageProps };
};
