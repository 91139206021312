import Link from 'next/link';
import { useContext } from 'react';
import { AuthContext } from 'src/contexts/authContext';
import SideModal from '../common/modals/SideModal';
import clsx from 'clsx';
import { getRouteFromDeepLink, groupByCreationDate } from 'src/libs/utils';

export enum NotificationStatus {
    CREATED,
    SEEN,
    CLICKED,
}

export enum NotificationTopic {
    KYC_STATUS = 'KYC_STATUS',
}

export type TNotification = {
    // moreInfo(deepLink: string, moreInfo: any, userId: any): string | import("url").UrlObject;
    id: string;
    userId: string;
    topic: NotificationTopic;
    status: NotificationStatus;
    message: string;
    seenAt: Date | null;
    createdAt: Date;
    updatedAt: Date | null;
    deletedAt: Date | null;
    firstClickAt: Date | null;
    deepLink: string;
    moreInfo?: {
        acceptedBy: string | null;
        createdBy: string | null;
        newStatus: string;
        oldStatus: string;
        referralId: number;
        userId: string;
    };
};

const NotificationItemImage = ({}) => (
    <div className="w-9.5 h-9.5 min-w-9.5 max-w-9.5 min-h-9.5 max-h-9.5 flex-none bg-grayColor rounded-full">
        &nbsp;
    </div>
);
const NotificationItemMessage = ({
    message,
    status,
}: {
    message: string;
    status: NotificationStatus;
}) => (
    <div
        className={clsx(
            'font-mont text-sm',
            status === NotificationStatus.CREATED && 'font-semibold',
        )}
    >
        {message}
    </div>
);
const NotificationItemTime = ({
    createdAt,
    status,
}: {
    createdAt: Date;
    status: NotificationStatus;
}) => (
    <div
        className={clsx(
            'font-mont text-sm',
            status === NotificationStatus.CREATED && 'font-semibold',
        )}
    >
        {createdAt.toLocaleString()}
    </div>
);
const NotificationItemStatus = ({ status }: { status: NotificationStatus }) =>
    Number(status) === NotificationStatus.CREATED ? (
        <div>
            <div className="w-3 h-3 min-w-3 max-w-3 min-h-3 max-h-3 flex-1 bg-redColor rounded-full" />
        </div>
    ) : null;

const NotificationSubtitle = ({ children }: { children: string }) => (
    <h2 className="mb-1.5 font-mont font-semibold text-grayText">{children}</h2>
);

const NotificationItem = (props: {
    userId: string;
    notification: TNotification;
    onClose: () => void;
}) => {
    const onClick = () => {
        props.onClose();
    };
    return (
        <Link
            passHref
            href={getRouteFromDeepLink(props.notification, props.userId)}
        >
            <div
                onClick={onClick}
                className=" cursor-pointer flex justify-between items-center hover:bg-hoverBg"
            >
                <NotificationItemImage />
                <div
                    className={clsx('p-3 grow', {
                        'font-semibold':
                            NotificationStatus.CREATED ===
                            Number(props.notification?.status),
                    })}
                >
                    <NotificationItemMessage {...props.notification} />
                    <NotificationItemTime {...props.notification} />
                </div>
                <NotificationItemStatus status={props.notification?.status} />
            </div>
        </Link>
    );
};

const NotificationSection = (props: {
    notifications: TNotification[];
    onClose: () => void;
}) => {
    const { userSession } = useContext(AuthContext);
    if (props.notifications.length === 0)
        return <div>You don&apos;t have any notifications yet</div>;

    const groupedNotifications = groupByCreationDate(props.notifications);
    const el = Object.entries(groupedNotifications).map(
        ([group, notifications], key) => (
            <div key={key}>
                <NotificationSubtitle key={group}>{group}</NotificationSubtitle>
                {notifications.map((notification) => (
                    <NotificationItem
                        key={notification.id}
                        userId={userSession?.userData.id || ''}
                        notification={notification}
                        onClose={props.onClose}
                    />
                ))}
            </div>
        ),
    );
    return <>{el}</>;
};

const demoNotifications = [
    {
        id: '1',
        userId: '1',
        topic: NotificationTopic.KYC_STATUS,
        status: NotificationStatus.CREATED,
        message: 'Your KYC status is pending',
        seenAt: null,
        createdAt: new Date(),
        updatedAt: new Date(),
        deletedAt: new Date(),
        firstClickAt: new Date(),
    },
    {
        id: '2',
        userId: '1',
        topic: NotificationTopic.KYC_STATUS,
        status: NotificationStatus.SEEN,
        message: 'Your KYC status is pending',
        seenAt: null,
        createdAt: new Date(),
        updatedAt: new Date(),
        deletedAt: new Date(),
        firstClickAt: new Date(),
    },
    {
        id: '2',
        userId: '1',
        topic: NotificationTopic.KYC_STATUS,
        status: NotificationStatus.SEEN,
        message: 'Your KYC status is pending',
        seenAt: null,
        createdAt: new Date(2022, 6, 29),
        updatedAt: new Date(),
        deletedAt: new Date(),
        firstClickAt: new Date(),
    },
    {
        id: '2',
        userId: '1',
        topic: NotificationTopic.KYC_STATUS,
        status: NotificationStatus.SEEN,
        message: 'Your KYC status is pending',
        seenAt: null,
        createdAt: new Date(2022, 6, 29),
        updatedAt: new Date(),
        deletedAt: new Date(),
        firstClickAt: new Date(),
    },
    {
        id: '2',
        userId: '1',
        topic: NotificationTopic.KYC_STATUS,
        status: NotificationStatus.SEEN,
        message: 'Your KYC status is pending',
        seenAt: null,
        createdAt: new Date(2022, 6, 28),
        updatedAt: new Date(),
        deletedAt: new Date(),
        firstClickAt: new Date(),
    },
] as TNotification[];

export const NotificationModal = ({
    open,
    onClose,
    notifications = [],
}: {
    open: boolean;
    onClose: () => any;
    notifications: TNotification[];
}) => {
    return (
        <SideModal open={open} onClose={onClose} headerLabel="Notifications">
            <NotificationSection
                notifications={notifications}
                onClose={onClose}
            />
        </SideModal>
    );
};
