import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { AuthContext, AuthStatus } from 'src/contexts/authContext';
import { AccountContext } from 'src/contexts/accountContext';
import {
    CONFIRM_EMAIL_PAGE_URL,
    FORGOT_PASSWORD_PAGE_URL,
    LOGIN_PAGE_URL,
    PRIVACY_POLICY_PAGE_URL,
    RECOVERY_PASSWORD_PAGE_URL,
    ROOT_PAGE_URL,
    SIGNUP_PAGE_URL,
    TERMS_AND_CONDITIONS_PAGE_URL,
    VERIFY_EMAIL_PAGE_URL,
    WELCOME_PAGE_URL,
} from 'src/constants/links';
import Loading from 'src/components/layout/Loading';
import { getQueryString } from 'src/libs/utils';

export const redirectToPage = (condition: any, router: any) => {
    const queryString = getQueryString(router.query);

    const {
        userIsSignedIn,
        emailIsVerified,
        phoneNumberIsVerified,
        welcomeFormIsFilled,
    } = condition;

    if (!userIsSignedIn) {
        router.push(LOGIN_PAGE_URL + queryString);
        return;
    }

    if (userIsSignedIn && emailIsVerified === false) {
        router.push(VERIFY_EMAIL_PAGE_URL + queryString);
        return;
    }

    if (
        userIsSignedIn &&
        (emailIsVerified || phoneNumberIsVerified) &&
        !welcomeFormIsFilled
    ) {
        router.push(WELCOME_PAGE_URL + queryString);
        return;
    }

    if (
        userIsSignedIn &&
        (emailIsVerified || phoneNumberIsVerified) &&
        welcomeFormIsFilled
    ) {
        router.push(ROOT_PAGE_URL);
        return;
    }
};

const GeneralRouter = ({ children }: any) => {
    const router = useRouter();
    const { asPath } = router;
    const { accountData, fetchAccountData, loadingAccountData } =
        useContext(AccountContext);
    const { authStatus, userSession } = useContext(AuthContext);
    const userId = userSession?.userData.id || '';

    const [shouldBeRendered, setShouldBeRendered] = useState(false);

    useEffect(() => {
        userId && fetchAccountData();
    }, [asPath, userId]);

    useEffect(() => {
        if (loadingAccountData) {
            return;
        }

        const userIsSignedIn = isUserSignIn(authStatus);
        const emailIsVerified = userIsSignedIn && isEmailVerified(accountData);
        const phoneNumberIsVerified =
            userIsSignedIn && isPhoneNumberVerified(accountData);
        const welcomeFormIsFilled =
            userIsSignedIn && isWelcomeFormFilled(accountData);
        const toPage = () => {
            redirectToPage(
                {
                    userIsSignedIn,
                    emailIsVerified,
                    phoneNumberIsVerified,
                    welcomeFormIsFilled,
                },
                router,
            );
        };

        if (isStaticPage(asPath)) {
            setShouldBeRendered(true);
        }

        if (userIsSignedIn) {
            if (accountData.id === undefined) {
                console.warn(
                    "If you see unexpected 'Loading...' check this rule",
                );
                return;
            }

            if (
                emailIsVerified === undefined &&
                phoneNumberIsVerified === undefined
            ) {
                console.warn('Registration problem!!! Pls check the user');
                return;
            }

            if (emailIsVerified === false && phoneNumberIsVerified === true) {
                console.warn('Verification problem!!! Pls check the user');
                return;
            }
        }

        if (
            asPath.includes(LOGIN_PAGE_URL) ||
            asPath.includes(SIGNUP_PAGE_URL) ||
            asPath.includes(FORGOT_PASSWORD_PAGE_URL) ||
            asPath.includes(RECOVERY_PASSWORD_PAGE_URL)
        ) {
            if (!userIsSignedIn) {
                setShouldBeRendered(true);
            } else {
                toPage();
            }
            return;
        }

        if (asPath.includes(VERIFY_EMAIL_PAGE_URL)) {
            if (
                userIsSignedIn &&
                emailIsVerified === false &&
                !welcomeFormIsFilled
            ) {
                setShouldBeRendered(true);
            } else {
                toPage();
            }
            return;
        }

        if (asPath.includes(WELCOME_PAGE_URL)) {
            if (
                userIsSignedIn &&
                (emailIsVerified || phoneNumberIsVerified) &&
                !welcomeFormIsFilled
            ) {
                setShouldBeRendered(true);
            } else {
                toPage();
            }
            return;
        }

        if (asPath.includes(CONFIRM_EMAIL_PAGE_URL)) {
            setShouldBeRendered(true);
            return;
        }
        if (!isAuthPages(asPath) && !isStaticPage(asPath)) {
            if (
                userIsSignedIn &&
                (emailIsVerified || phoneNumberIsVerified) &&
                welcomeFormIsFilled
            ) {
                setShouldBeRendered(true);
            } else {
                toPage();
            }
            return;
        }
    }, [authStatus, accountData, loadingAccountData]);

    return shouldBeRendered ? <>{children}</> : <Loading />; //show nothing or a loader
};

export default GeneralRouter;

const isStaticPage = (asPath: string) => {
    if (
        asPath.includes(TERMS_AND_CONDITIONS_PAGE_URL) ||
        asPath.includes(PRIVACY_POLICY_PAGE_URL)
    ) {
        return true;
    }
};

const isUserSignIn = (authStatus: AuthStatus) => {
    return authStatus === AuthStatus.SignedIn;
};

const isEmailVerified = (data: any) => {
    return data.emailVerified;
};

const isPhoneNumberVerified = (data: any) => {
    return data.phoneNumberVerified;
};

const isWelcomeFormFilled = (data: any) => {
    return (
        !!data?.firstName && !!data?.lastName && !!data.phone && !!data.email
        // && !!data?.signId //--------------- DocuSign temporarily disabled --------------------
    );
};

const isAuthPages = (asPath: string) => {
    if (
        asPath.includes(LOGIN_PAGE_URL) ||
        asPath.includes(SIGNUP_PAGE_URL) ||
        asPath.includes(WELCOME_PAGE_URL) ||
        asPath.includes(CONFIRM_EMAIL_PAGE_URL) ||
        asPath.includes(VERIFY_EMAIL_PAGE_URL) ||
        asPath.includes(FORGOT_PASSWORD_PAGE_URL) ||
        asPath.includes(RECOVERY_PASSWORD_PAGE_URL)
    ) {
        return true;
    }
};
