import axios from 'axios';
import isNumber from 'lodash/isNumber';
import { NotificationStatus } from 'src/components/general/NotificationModal';
import { getSession } from './cognito';
import { NEXT_PUBLIC_API_ENDPOINT } from './config';

const getHeaders = async (): Promise<Record<string, string>> => {
    const userSession = await getSession();

    return {
        Authorization: userSession ? `Bearer ${userSession?.accessToken}` : '',
    };
};

export function sendVerificationEmail(email: string) {
    return axios.post(
        '/auth/send-verification-email',
        { email },
        {
            baseURL: NEXT_PUBLIC_API_ENDPOINT,
        },
    );
}

export function verifyEmail(email: string, token: string) {
    return axios.post(
        '/auth/verify-email',
        { token, email },
        {
            baseURL: NEXT_PUBLIC_API_ENDPOINT,
        },
    );
}

export async function markNotificationAsClicked(id: string) {
    console.debug('markNotificationAsClicked', id);
    const body = {
        notifications: [
            {
                id,
                status: NotificationStatus.CLICKED,
            },
        ],
    };
    return axios.patch('/notifications', body, {
        baseURL: NEXT_PUBLIC_API_ENDPOINT,
        headers: await getHeaders(),
    });
}

export async function redirectToReferralPaymentLink(
    referralId: number | string,
): Promise<void> {
    type PaymentLinkBody = {
        referralId: number;
    };

    const headers = await getHeaders(); //FYI this is hack don't move it inside axios fn!!!
    const body: PaymentLinkBody = {
        referralId: isNumber(referralId) ? referralId : parseInt(referralId),
    };
    const r = await axios.post('/payments/stripe/links', body, {
        baseURL: NEXT_PUBLIC_API_ENDPOINT,
        headers,
    });

    const {
        data: { url },
    } = r;

    window.location.href = url;
}
