import XIcon from '@heroicons/react/solid/XIcon';

type TProps = {
    label: string;
    onClose: () => void;
};

const SideModalHeader = ({ label, onClose }: TProps) => {
    return (
        <div className="flex relative left-30 justify-between border-b border-gray-300 md:w-[710px] max-w-[110%] py-4 pl-7.5 -ml-7.5 z-10">
            <h1 className="text-2xl font-semibold text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap">
                {label}
            </h1>
            <div
                className="block md:hidden relative px-7 py-2 cursor-pointer"
                onClick={onClose}
            >
                <XIcon className="w-5 h-5 text-black" />
            </div>
        </div>
    );
};

export default SideModalHeader;
